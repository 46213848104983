@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face { font-family: arlbold; src: url('/public/fonts/arlrdbd.ttf');  font-display: swap;}
@import "react-slideshow-image/dist/styles.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.phoneMenuIcon {
  @apply absolute  text-5xl transition-all duration-300
}
.myclass { 
  /* all anchor styles */
}
.myclass_hover:hover {
  /* example color */
  
}
.spiral {
  background-image: url("/public/images/Spiral_Background_1980.jpg");
  background-size: cover;
  
  background-position: 50% 0%;
  background-repeat: no-repeat;
}
.parallax {

  perspective: 1px;

  height: 100vh;
  overflow-x: hidden;
  
}
.parallax__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parallax__layer--back {
  transform: translateZ(-1px) scale(6.3);
}
.parallax__layer--base {
  transform: translateZ(-1);
}
.playingField {
  position: relative;
  overflow: hidden;
 
}
.playingField::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 80%;
  top: -50%;
  left: -50%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/public/images/play_Board.jpg");
  transform: scaleY(-45);
}
.teeest {
  transform-box: rotateX(40deg);
}

.iphone-x {
  position: relative;
  margin: auto;
  top: -36%;
  width: 320px;
  height: 700px;
  background-color: darkslategray;
  background-image: url("/public/images/VCD_5rot.png");
  background-size:   cover;
    background-repeat:   no-repeat;
    background-position: center;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
}
.iphone-x:before, .iphone-x:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}
.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}
.iphone-x i, .iphone-x b, .iphone-x s, .iphone-x span {
  position: absolute;
  display: block;
  color: transparent;
}
.iphone-x i {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(255, 255, 255, 0.2);
}
.iphone-x b {
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(255, 255, 255, 0.2);
}
.iphone-x b:after {
  content: '';
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}
.iphone-x s {
  
  
}
.iphone-x span {
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  left: 30px;
}
.iphone-x span + span {
  left: auto;
  right: 30px;
}
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: rgb(253,248,212) transparent rgb(210,233,233) transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.imgForce_cards {
  display: block;
  width: auto;
  height: auto;
  max-width: 10rem;
  max-height: 11rem;
  @apply m-2 m-auto
}
.insta {
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  background-clip: text;
  -webkit-background-clip: text;
  color: chocolate;
    
}

.cookiepolicy p {
 
}
.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999999999;
  overflow: hidden;
  background: #fff;
}

@media screen and (max-width: 600px) {
  .spiral {
    background-image: url("/public/images/Spiral_Background_600.jpg");
    background-size: cover;
    
    background-position: 50% -25%;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 425px) {
  .captcha {
      width: 80%;
  }
  .iphone-x {
    position: relative;
    margin: auto;
    top: -25%;
    width: 320px;
    height: 700px;
    background-color: darkslategray;
    background-image: url("/public/images/VCD_5rot.png");
    background-size:   cover;
      background-repeat:   no-repeat;
      background-position: center;
    border-radius: 40px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
      width: 93%;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
      width: 100%;
      float: left;
  }
}